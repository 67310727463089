// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-buying-guide-js": () => import("./../src/pages/buying-guide.js" /* webpackChunkName: "component---src-pages-buying-guide-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-load-outs-js": () => import("./../src/pages/load-outs.js" /* webpackChunkName: "component---src-pages-load-outs-js" */),
  "component---src-pages-mine-trade-js": () => import("./../src/pages/mine-trade.js" /* webpackChunkName: "component---src-pages-mine-trade-js" */),
  "component---src-pages-quick-links-community-tools-js": () => import("./../src/pages/quick-links/community-tools.js" /* webpackChunkName: "component---src-pages-quick-links-community-tools-js" */),
  "component---src-pages-quick-links-how-to-js": () => import("./../src/pages/quick-links/how-to.js" /* webpackChunkName: "component---src-pages-quick-links-how-to-js" */),
  "component---src-pages-quick-links-js": () => import("./../src/pages/quick-links.js" /* webpackChunkName: "component---src-pages-quick-links-js" */),
  "component---src-pages-quick-links-rsi-official-js": () => import("./../src/pages/quick-links/rsi-official.js" /* webpackChunkName: "component---src-pages-quick-links-rsi-official-js" */),
  "component---src-pages-quick-links-streamer-tv-js": () => import("./../src/pages/quick-links/streamer-tv.js" /* webpackChunkName: "component---src-pages-quick-links-streamer-tv-js" */)
}

